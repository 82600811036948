<template>
  <b-card-code>
    <div v-if="$can('create','Exam Routine')" class="custom-search d-flex">
        <b-button variant="outline-primary" @click="showModal()">
          Add new
        </b-button>
    </div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>
      <div class="custom-search d-flex justify-content-start">
          <vue-excel-xlsx
              :data="getData"
              :columns="columns10"
              :filename="'filename'"
              :sheetname="'subjects'"
          >
              <b>Excel</b>
          </vue-excel-xlsx>
          <vue-json-to-csv :json-data="getData"
                           :csv-title="'My_CSV'"
          >
              <button>
                  <b>CSV</b>
              </button>
          </vue-json-to-csv>
          <button @click="pdfGenerate()">
              <b>PDF</b>
          </button>
      </div>

    <!-- table -->
    <vue-good-table
        style="white-space: nowrap;text-align: center;vertical-align : middle;"
        :columns="columns"
        :rows="exam_routines"
        :rtl="direction"
        :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
        :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
          slot="table-row"
          slot-scope="props"
      >
        <!-- Column: index -->
        <span v-if="props.column.field === 'id'">
          {{ props.row.originalIndex+1 }}
        </span>
        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'class_period'">
          {{ props.row.class_period?moment(props.row.class_period.start_time,'hh:mm').format('hh:mm a')+' - '+moment(props.row.class_period.end_time,'hh:mm').format('hh:mm a'):'' }}
        </span>
        <span v-else-if="props.column.field === 'is_active'">
          <b-form-checkbox v-if="$can('status change','Exam Routine') && props.row.is_approved===1" :name="'check-button'+props.row.originalIndex" :id="'check-button'+props.row.originalIndex" @change="statusChange(props.row.id)" v-model="props.row.is_active" :value="1" switch>
            </b-form-checkbox>
          <b-badge v-else-if="props.row.is_approved===1" :variant="statusVariant(props.row.is_active)">
            {{ props.row.is_active?'Active':'Deactivated' }}
          </b-badge>
          <b-badge v-else :variant="statusVariant(0)">
            {{ props.row.is_approved===0?'pending':'Declined' }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <template >
              <b-button v-if="!props.row.is_approved && (findAccess(props.row.id) || user.role_id===1)"
                        data-toggle="tooltip" data-placement="top" title="Approve"
                        variant="primary" @click="approve(props.row.id)">
                <feather-icon class="text-body " icon="CheckIcon" size="15" />
              </b-button>
              <b-button v-if="!props.row.is_approved && (findAccess(props.row.id) || user.role_id===1)"
                        data-toggle="tooltip" data-placement="top" title="Decline"
                        variant="danger" @click="decliend(props.row.id)">
                <feather-icon class="text-body " icon="CrosshairIcon" size="15" />
              </b-button>
              <b-button v-if="!props.row.is_approved && props.row.approvalable.length<1 && user.role_id===1"
                        data-toggle="tooltip" data-placement="top" title="Forword"
                        variant="secondary" @click="showModal2(props.row)">
                <feather-icon class="text-body " icon="GitBranchIcon" size="15" />
              </b-button>
              <b-button v-if="!props.row.is_approved && findAccess(props.row.id)"
                        data-toggle="tooltip" data-placement="top" title="Forword"
                        variant="secondary" @click="showModal2(props.row)">
                <feather-icon class="text-body " icon="GitBranchIcon" size="15" />
              </b-button>
              <b-button v-if="$can('remove','Exam Routine') && props.row.is_approved"
                        data-toggle="tooltip" data-placement="top" title="Remove"
                        :variant="'danger'" @click="remove(props.row.id)">
                <feather-icon class="text-body " icon="TrashIcon" size="15" />
              </b-button>
            </template>
          </span>
        </span>
      </template>

      <!-- pagination -->
      <template
          slot="pagination-bottom"
          slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
                v-model="pageLength"
                :options="['10','15','20','50','100','500']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <!-- modal login-->
    <b-modal ref="my-modal" size="lg"
             hide-footer
             :title="'Add new Exam Routine'"
    >
      <!-- form -->
      <validation-observer ref="simpleRules">
        <form ref="exam_routine_add" style="min-height: 350px">
          <b-row>
            <b-col md="4">
              <b-card-text>
                <span>Select Class </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  name="class"
                  rules="required"
              >
                <select name="classes_id" v-model="classes_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option  :value="null">Select One</option>
                  <option v-for="(cls, key) in classes" :value="cls.id" :key="key">{{cls.name}}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>Select Versions </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  name="version"
                  rules="required"
              >
                <select name="version_id" v-model="version_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option  :value="null">Select One</option>
                  <option v-for="(version, key) in versions" :value="version.id" :key="key">{{version.name}}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>Select Shifts </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  name="shift"
                  rules="required"
              >
                <select name="shift_id" v-model="shift_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option  :value="null">Select One</option>
                  <option v-for="(shift, key) in shifts" :value="shift.id" :key="key">{{shift.name}}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>Select Wing </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  name="wing"
                  rules="required"
              >
                <select name="wing_id" v-model="wing_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option  :value="null">Select One</option>
                  <option v-for="(wing, key) in wings" :value="wing.id" :key="key">{{wing.name}}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>Select Student Groups </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  name="student group"
                  rules="required"
              >
                <select name="student_group_id" v-model="student_group_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option  :value="null">Select One</option>
                  <option v-for="(sgrp, key) in student_groups" :value="sgrp.id" :key="key">{{sgrp.name}}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>Select Academic Years </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  name="academic year"
                  rules="required"
              >
                <select name="academic_year_id" v-model="academic_year_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option  :value="null">Select One</option>
                  <option v-for="(aYear, key) in academic_years" :value="aYear.id" :key="key">{{aYear.year}}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>Select Exam Types </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  name="exam type"
                  rules="required"
              >
                <select name="exam_type_id" v-model="exam_type_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option  :value="null">Select One</option>
                  <option v-for="(eType, key) in exam_types" :value="eType.id" :key="key">{{eType.name}}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>Select Subject </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  name="subject"
                  rules="required"
              >
                <select name="subject_id" v-model="subject_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option  :value="null">Select One</option>
                  <option v-for="(subject, key) in subjects" :key="key" :value="subject.id">{{subject.name+' '+ subject.code}}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>Select Exam Slot </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  name="exam slot"
                  rules="required"
              >
                <select v-model="class_period_id" name="class_period_id" class="form-control"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'">
                  <option  :value="null">Select One</option>
                  <option v-for="(period,key) in active_class_periods" :key="key" :id="period.start_time" :value="period.id">{{moment(period.start_time,'hh:mm').format('hh:mm a') + '-'+ moment(period.end_time,'hh:mm').format('hh:mm a')}}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <validation-provider
                    #default="{ errors }"
                    name="exam date"
                    rules="required"
                >
                  <b-form-datepicker
                      v-model="exam_date"
                      name="exam_date"
                      type="date"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Exam date"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>Need approval?</span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  name="need approval"
                  rules="required"
              >
                <b-form-radio-group
                    v-model="is_approved"
                    :options="options"
                    stacked
                    name="is_approved"
                >
                </b-form-radio-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4" v-if="is_approved===0">
              <b-card-text>
                <span>Select Employee </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  name="employee"
                  rules="required"
              >
                <select v-model="employee_id" name="employee_id" class="form-control"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'">
                  <option  :value="null">Select One</option>
                  <option v-for="(employee, key) in employees" :key="key" :id="employee.id" :value="employee.id">{{ employeeNameById(employee.id) +' '+ employeeIdById(employee.id)}}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <!-- submit button -->
            <b-col md="4" class="mt-2">
              <b-button
                  variant="primary"
                  type="submit"
                  @click.prevent="addExamRoutine"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </form>
      </validation-observer>
    </b-modal>
    <b-modal ref="my-modal2"
             hide-footer size="lg"
             :title="'Foreword Information'"
    >
      <b-row>
        <b-col md="12">
          <b-table-simple hover small caption-top responsive>
            <b-thead>
              <b-th class="text-center"  :colspan="1">Employee ID</b-th>
              <b-th class="text-center"  :colspan="1">Employee Name</b-th>
              <b-th class="text-center"  :colspan="1">Comment</b-th>
              <b-th class="text-center"  :colspan="1">Status</b-th>
            </b-thead>
            <b-tbody>
              <b-tr v-for="(grade,k) in selected_row.approvalable" :key="k">
                <b-th class="text-center">{{ employeeIdById(grade.employee_id) }}</b-th>
                <b-th class="text-center">{{ employeeNameById(grade.employee_id) }}</b-th>
                <b-th class="text-center">{{ grade.comment }}</b-th>
                <b-th class="text-center">{{ status3[parseInt(grade.status)] }}</b-th>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
      <!-- form -->
      <validation-observer ref="simpleRules">
        <b-form ref="leave_request">
          <b-row>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                    #default="{ errors }"
                    name="employee"
                    rules="required"
                >
                  <select name="employee_id" v-model="employee_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                    <option  :value="null">Select One</option>
                    <option v-for="(employee, key) in employees" :value="employee.id" :key="key">{{employeeNameById(employee.id)}}- {{employee.employee_id}}</option>
                  </select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group>
                <validation-provider
                    #default="{ errors }"
                    name="comment"
                    rules="required"
                >
                  <b-form-textarea
                      v-model="comment"
                      name="comment"
                      :state="errors.length > 0 ? false:null"
                      placeholder="comment"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- submit button -->
            <b-col>
              <b-button
                  variant="primary"
                  type="submit"
                  @click.prevent="validationForm"
              >
                {{ $t('Submit') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>

  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,BBadge, BPagination, BFormGroup, BFormInput, BFormSelect,BRow, BCol,
  BCardText,BFormRadioGroup,BFormDatepicker,BFormCheckbox,BModal,BFormTextarea,
  BForm,BTableSimple,BThead,BTr,BTh,BTbody,
} from 'bootstrap-vue'
import {required,integer} from '@validations'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import apiCall from "@/libs/axios";
import Ripple from 'vue-ripple-directive'
import {mapGetters} from "vuex";
import moment from "moment";
import VueExcelXlsx from "vue-excel-xlsx";
import VueJsonToCsv from 'vue-json-to-csv'
import Vue from "vue";
import download from "downloadjs";

Vue.use(VueExcelXlsx);
export default {
  name:'ExamRoutine',
  components: {
    BCardCode,
    VueGoodTable,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,BModal,BFormTextarea,
    BForm,BTableSimple,BThead,BTr,BTh,BTbody,
    BButton,BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BRow, BCol,BCardText,BFormRadioGroup,
    BFormDatepicker, VueJsonToCsv
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      moment,
      is_approved: 1,
      options: [
        { text: 'Yes', value: 0 },
        { text: 'No', value: 1 },
      ],
      required,
      pageLength: 10,
      dir: false,
      selected_row:{},
      columns: [
        {
          label: 'SL',
          field: 'id',
        },
        {
          label: 'Class',
          field: 'classes.name',
        },
        {
          label: 'Version',
          field: 'version.name',
        },
        {
          label: 'Shift',
          field: 'shift.name',
        },
        {
          label: 'Student Group',
          field: 'student_group.name',
        },
        {
          label: 'Academic year',
          field: 'academic_year.year',
        },
        {
          label: 'Exam Type',
          field: 'exam_type.name',
        },
        {
          label: 'Subject',
          field: 'subject.name',
        },
        {
          label: 'Exam Date',
          field: 'exam_date',
        },
        {
          label: 'Exam Slot',
          field: 'class_period',
        },
        {
          label: 'Status',
          field: 'is_active',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      searchTerm: '',
      status: {
        1: 'active',
        0: 'deactivate',
      },
      status2: {
        1: 'Yes',
        0: 'No',
      },
      status3:{
        0:'Pending',
        1:'Approved',
        2:'Foreword',
        3:'Declined'
      },
      integer,
      classes:[],
      versions:[],
      student_groups:[],
      shifts:[],
      wings:[],
      student_group_id:null,
      version_id:null,
      shift_id:null,
      classes_id:null,
      class_period_id:null,
      academic_year_id:null,
      wing_id:null,
      subject_id:null,
      exam_type_id:null,
      exam_date:null,
      employee_id:null,
      comment:'',
        columns10: [
            {
                label: 'Class',
                field: 'Class',
            },
            {
                label: 'Version',
                field: 'Version',
            },
            {
                label: 'Shift',
                field: 'Shift',
            },
            {
                label: 'Student Group',
                field: 'StudentGroup',
            },
            {
                label: 'Academic Year',
                field: 'AcademicYear',
            },
            {
                label: 'Exam Type',
                field: 'ExamType',
            },
            {
                label: 'Subject',
                field: 'Subject',
            },
            {
                label: 'Exam Date',
                field: 'ExamDate',
            },
            {
                label: 'Exam Slot',
                field: 'ExamSlot',
            },
            {
                label: 'Status',
                field: 'Status',
            },
            {
                label: 'Is Approverd',
                field: 'IsApproverd',
            },
        ],
    }
  },
  methods:{
    statusChange(id){
      apiCall.put(`/exam/routine/status/change${id}`).then((response)=>{
        this.$toaster.success(response.data.message);
        this.$store.dispatch('GET_ALL_EXAM_ROUTINE');
      }).catch((error)=>{
        this.$toaster.error(error.response.data.message);
        this.$store.dispatch('GET_ALL_EXAM_ROUTINE');
      });
    },
    approve(id){
      this.$swal({
        title: 'Are you sure?',
        text: "You want to approve!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, approve it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          apiCall.put(`/exam/routine/approve${id}`).then((response)=>{
            this.$toaster.success(response.data.message);
            this.$store.dispatch('GET_ALL_EXAM_ROUTINE');
          }).catch((error)=>{
            this.$toaster.error(error.response.data.message);
          });
        }
      })
    },
    remove(id){
      this.$swal({
        title: 'Are you sure?',
        text: "You want to remove this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, remove it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          apiCall.delete(`/exam/routine/remove${id}`).then((response)=>{
            this.$toaster.success(response.data.message);
            this.$store.dispatch('GET_ALL_EXAM_ROUTINE');
          }).catch((error)=>{
            this.$toaster.error(error.response.data.message);
          });
        }
      })
    },
    showModal() {
      this.$refs['my-modal'].show();
    },
    hideModal() {
      this.$refs['my-modal'].hide();
    },
    showModal2(row) {
      this.selected_row=row;
      this.$refs['my-modal2'].show();
    },
    hideModal2() {
      this.$refs['my-modal2'].hide();
    },
    addExamRoutine(){
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let data =new FormData(this.$refs.exam_routine_add);
          apiCall.post('/exam/routine/store',data).then((response)=>{
            if (response.data.status === 'success'){
              this.$toaster.success(response.data.message);
              this.$store.dispatch('GET_ALL_EXAM_ROUTINE');
              this.subject_id=null;
              this.class_period_id=null;
              this.$refs.simpleRules.reset();
            }else {
              this.$toaster.error(response.data.message);
            }
          }).catch((error)=>{
            if (error.response.status ==422) {
              Object.keys(error.response.data.errors).map((field) => {
                this.$toaster.error(error.response.data.errors[field][0]);
              })
            }else this.$toaster.error(error.response.data.message);
          });
        }
      })
    },
    decliend(id){
      this.$swal({
        title: 'Are you sure?',
        text: "You want to declined this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, declined it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          apiCall.put(`/exam/routine/declined${id}`).then((response)=>{
            this.$store.dispatch('GET_ALL_EXAM_ROUTINE');
            this.$swal({
              icon: 'success',
              title: 'Status Change!',
              text: response.data.message,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }).catch((error)=>{
            this.$toaster.error(error.response.data.message);
          });
        }
      })
    },
    validationForm(){
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let data =new FormData(this.$refs.leave_request);
          apiCall.post(`/exam/routine/forward${this.selected_row.id}`,data).then((response)=>{
            this.hideModal2();
            this.$toaster.success(response.data.message);
            this.selected_row={};
            this.employee_id=null;
            this.comment='';
            this.$store.dispatch('GET_ALL_EXAM_ROUTINE');
          }).catch((error)=>{
            if (error.response.status == 422) this.$toaster.error(error.response.data.errors.name);
            else this.$toaster.error(error.response.data.message);
          });
        }
      })
    },
    findAccess(id){
      let ff = this.exam_routines.find(item=>item.id===parseInt(id));
      if(ff){
        if(ff.approvalable.length<1) return false;
        else return ff.approvalable[ff.approvalable.length -1].employee_id===this.user.userable.id;
      }
      else return false;
    },
    /*
    * method for get all active class wise version, shift, student groups
    * */
    async getAll(){
      await apiCall.get('/get/active/class/with/v/s/sg/wings').then((response)=>{
        this.classes = response.data;
      }).catch(()=>{
        this.classes=[];
      });
    },
      pdfGenerate(){
          /*only change able*/
          let title="Exam Routine";
          let clm=['Class','Version','Shift','StudentGroup', 'AcademicYear','ExamType', 'Subject', 'ExamDate', 'ExamSlot', 'Status', 'IsApproverd'];
          /*change able end*/
          let data =new FormData();
          data.append('title',title);
          data.append('columns',JSON.stringify(clm));
          data.append('data',JSON.stringify(this.getData));
          apiCall.post('/get/common/table/export/pdf',data,{responseType: 'blob'}).then((response)=>{
              const content = response.headers['content-type'];
              download(response.data,'subject.pdf', content)
          }).catch((error) => {
              this.$toaster.error(error.response.data.message);
          });
      },
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        1 : 'light-success',
        0 : 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    ...mapGetters(['exam_routines','academic_years','employees','employeeIdById','employeeNameById']),
      getData(){
          return this.exam_routines.map(item => ({
              Class: item.classes?item.classes.name:'',
              Version: item.version?item.version.name:'',
              Shift: item.shift?item.shift.name:'',
              StudentGroup: item.student_group?item.student_group.name:'',
              AcademicYear: item.academic_year?item.academic_year.year:'',
              ExamType: item.exam_type?item.exam_type.name:'',
              Subject: item.subject?item.subject.name:'',
              ExamDate: item.exam_date?item.exam_date:'',
              ExamSlot: item.class_period?item.class_period.start_time + "-" + item.class_period.end_time :'',
              Status: item.is_active ? 'Active' : 'Inactive',
              IsApproverd: item.is_approved ? 'Approved' : 'Not Approved'
          }));
      },
    active_class_periods(){
      return this.$store.getters.active_class_periods.filter(item=>item.type==2);
    },
    subjects(){
      let sub = this.$store.getters.active_subjects;
      sub = sub.map((item)=>{
        if (item.classes_id == this.classes_id && item.version_id == this.version_id && item.student_group_id == this.student_group_id) return item;
      }).filter(Boolean);
      return sub;
    },
    exam_types(){
      let sub = this.$store.getters.active_exam_types;
      sub = sub.map((item)=>{
        if (item.classes_id == this.classes_id && item.version_id == this.version_id
            && item.student_group_id == this.student_group_id && item.academic_year_id == this.academic_year_id) return item;
      }).filter(Boolean);
      return sub;
    },
    user(){
      return JSON.parse(localStorage.getItem('userData'))
    }
  },
  created() {
    this.$store.dispatch('GET_ALL_EXAM_ROUTINE');
    this.getAll();
    this.$store.dispatch('GET_ACTIVE_CLASS_PERIOD');
    this.$store.dispatch('GET_ALL_ACADEMIC_YEAR');
    this.$store.dispatch('GET_ACTIVE_SUBJECT');
    this.$store.dispatch('GET_ACTIVE_EXAM_TYPE');
    if(this.employees.length <1) this.$store.dispatch('GET_ALL_EMPLOYEE');
  },
  watch:{
    /*watcher for class on change*/
    classes_id(){
      let s_classes = this.classes.find(item=>item.id===this.classes_id);
      this.version_id=null;
      this.shift_id=null;
      this.wing_id=null;
      this.student_group_id=null;
      this.subject_id=null;
      if (s_classes){
        this.versions = s_classes.versions??[];
        this.shifts = s_classes.shifts??[];
        this.wings = s_classes.wings??[];
        this.student_groups = s_classes.student_groups??[];
      }else {
        this.versions =[];
        this.shifts =[];
        this.wings =[];
        this.student_groups =[];
      }
    },
    version_id(){
      this.subject_id=null;
    },
    student_group_id(){
      this.subject_id=null;
    }
  }
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>